import httpClient from "./http-client";

const ENDPOINT = "/configuracion/cuenta-bancaria";

export interface CuentaBancaria {
  numero: string;
}

export interface CuentaBancariaCreateDto {
  id?: number;
  banco: string;
  tipoCuenta: string;
  numero: string;
  titular: string;
  tipoIdentificacion: string;
  identificacion: string;
  estado: string;
}

class CuentaBancariaService {
  getCuentas(): Promise<CuentaBancariaCreateDto[]> {
    return httpClient.get(ENDPOINT).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  getCuentasDisponibles(): Promise<CuentaBancariaCreateDto[]> {
    return httpClient.get(`${ENDPOINT}/disponibles`).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }

  createCuenta(createDto: CuentaBancariaCreateDto) {
    return httpClient.post(ENDPOINT, createDto);
  }
}
export default new CuentaBancariaService();
