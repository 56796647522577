


















































import CuentaBancariaAdd from "@/components/admin/cuenta-bancaria/CuentaBancariaAdd.vue";
import Vue from "vue";
import CuentaBancariaService, {
  CuentaBancaria
} from "../../api/CuentaBancariaService";

export default Vue.extend({
  components: {
    CuentaBancariaAdd
  },
  metaInfo: {
    title: "Cuentas Bancarias"
  },
  data() {
    return {
      dialog: false,
      cuenta: {
        banco: "",
        tipoCuenta: "",
        numero: "",
        titular: "",
        tipoIdentificacion: "",
        identificacion: "",
        estado: ""
      } as CuentaBancaria,
      headers: [
        { text: "Banco", value: "banco" },
        { text: "Tipo Cuenta", value: "tipoCuenta" }, // display
        { text: "Número", value: "numero" },
        { text: "Titular", value: "titular" },
        { text: "Tipo Identificación", value: "tipoIdentificacion" }, //display
        { text: "Identificación", value: "identificacion" },
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
      ],
      cuentas: [] as CuentaBancaria[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        banco: "",
        tipoCuenta: "AH",
        numero: "",
        titular: "",
        tipoIdentificacion: "CED",
        identificacion: "",
        estado: "1"
      };
      this.cuenta = newDto;
    },
    editItem(item: CuentaBancaria) {
      this.cuenta = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getCuentas() {
      CuentaBancariaService.getCuentas()
        .then(response => {
          this.cuentas = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Cuentas Bancarias",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getCuentas();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
