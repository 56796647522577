















































































import Vue from "vue";
import CuentaBancariaService from "../../../api/CuentaBancariaService";
export default Vue.extend({
  props: ["dialog", "cuenta"],
  data() {
    return {
      editedIndex: -1,
      tipoCuentaOptions: [
        { text: "Ahorro", value: "AH" },
        { text: "Corriente", value: "CO" }
      ],
      tipoIdentificacionOptions: [
        { text: "Cédula", value: "CED" },
        { text: "RUC", value: "RUC" }
      ],
      estadoOptions: [
        { text: "Activo", value: "1" },
        { text: "Inactivo", value: "2" }
      ],
      editedItem: {
        banco: "",
        tipoCuenta: "",
        numero: "",
        titular: "",
        tipoIdentificacion: "",
        identificacion: "",
        estado: ""
      }
    };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      //this.dialog = false;
      this.dialogChange(false);
      /*
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      */
    },
    save() {
      CuentaBancariaService.createCuenta(this.editedItem)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Cuenta Bancaria",
            text: "Creada exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Cuenta Bancaria",
            text: "No se pudo crear"
          });
        });
    }
  },
  computed: {
    formTitle: {
      get(): string {
        return this.editedIndex === -1 ? "Nuevo" : "Editar";
      }
    }
  },
  watch: {
    cuenta: function(newVal) {
      this.editedItem = { ...newVal };
    }
  }
});
